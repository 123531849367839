<template>
  <div v-if="!loading" class="wrapper  dashboard-wrapper collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
    <div v-if="loading" id="toHidden"
            style="
                position: absolute;
                top     :0;
                left    : 0;
                height  : 100%;
                width   :  100%;
                background-color:azure;
                z-index : 1000;
                opacity : 0.6;"
            >
    </div>
    <b-icon  v-if="loading" style="
                z-index: 2000;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                "
        icon="three-dots"
        animation="cylon"
        font-scale="4">
    </b-icon>
    <b-container class="container" style="margin-top: 5%; max-width: 94%;">
      <b-row class="page-header-bs">
        <b-col style="display: flex">
          <h2 class="card-title">
            {{ $t("DashBoardStaff.Title") }}
          </h2> 
        </b-col>
      </b-row>
      
      <b-row>
        <b-col cols="12">
          <card>
            <b-row>
              <b-col cols="4" class="m-auto">
                <span><h6 class=" mb-0">{{ $t("DashBoardStaff.Tables.TitleStaff") }}</h6></span>
              </b-col>
              <b-col >
                <b-button-toolbar v-if="staffs.length < 3" key-nav aria-label="Toolbar with button groups" style="float: right;">
                  <b-button-group class="mx-1">
                    <n-button
                    id="newAdmin"
                    class="btn-simple ncm-btn"
                    style="font-weight: 800 !important;"
                    target="_blank"
                    @click.native="modals.newStaff = true"
                  >
                  <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                  {{ $t("DashBoardStaff.ButtonStaff") }}
                  </n-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  style="overflow-x:auto;" 
                  ref="staffTable"
                  id="staffTable"
                  :fields="headers" 
                  :items="staffs"
                  show-empty
                  responsive="sm"> 
                  <template #empty="">
                      {{$t('DashBoardStaff.Empty')}}
                  </template>
                  <template #cell(phone)="data">
                      <b-button v-if="data.item.phone != null && data.item.phone != undefined && data.item.phone != ''" @click="copyToClipboard(data.item.phone)" class="action-table" v-b-tooltip.hover.viewport :title=data.item.phone>
                          <b-icon icon="telephone"></b-icon>
                      </b-button>
                      <b-button v-else class="action-table">
                          <p style=" margin: 0">N/A</p>
                      </b-button>
                  </template>
                  <template #cell(email)="data">
                      <b-button v-if="data.item.email != null" class="action-table" v-b-tooltip.hover.viewport :title=data.item.email @click="copyToClipboard(data.item.email)">
                          <b-icon icon="mailbox"></b-icon>
                      </b-button>
                      <b-button v-else class="action-table">
                          <p style=" margin: 0">N/A</p>
                      </b-button>
                  </template>
                  <template #cell(role)="data">
                    {{ data.item.role_name}}
                    <b-button  v-if="data.item.role_name != undefined" class="action-table pen" v-b-tooltip.hover.viewport :title="$t('DashBoardStaff.Tooltips.Modify') " @click="openAssignStaffRole(data.item)">
                      <b-icon icon="pencil-square"></b-icon>
                    </b-button>
                    <b-button  v-if="data.item.role_name == undefined" class="action-table pen" v-b-tooltip.hover.viewport :title="$t('DashBoardStaff.Tooltips.Assign') " @click="openAssignStaffRole(data.item)">
                      <b-icon icon="person-plus"></b-icon>
                    </b-button>
                  </template>
               <template #cell(is_active)="data">
                    <template v-if="!data.item.is_active">
                      <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('DashBoardStaff.Tooltips.Enable')" @click="changeStatus(data.item, true)">
                        <b-icon icon="toggle-off"></b-icon>
                      </b-button>
                    </template>  
                    <template v-else>
                      <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('DashBoardStaff.Tooltips.Disable')" @click="changeStatus(data.item, false)">
                        <b-icon icon="toggle-on"></b-icon>
                      </b-button>
                    </template>
                  </template>

                <template #cell(options)="data">
                    <b-button class="action-table pen" v-b-tooltip.hover.viewport :title="$t('DashBoardStaff.Tooltips.Modify') " @click="openEditStaffModal(data.item)">
                      <b-icon icon="pen"></b-icon>
                    </b-button>
                    <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('DashBoardStaff.Tooltips.Delete')" @click="openDeleteStaffModal(data.item)">
                      <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
                <template #row-details="row">
                    <b-card class="row-details-card">
                        <b-row>
                            <b-col class="row-details-col">
                                {{ row.item.name }}
                            </b-col>
                        </b-row>
                    </b-card>
                </template>
                </b-table>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <card>
            <b-row>
              <b-col cols="4" class="m-auto">     
                <span><h6>{{ $t("DashBoardStaff.Tables.TitleRole") }}</h6></span>
              </b-col>
              <b-col>
                <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right">
                  <b-button-group>
                    <n-button
                    id="newAdmin"
                    class="btn-simple ncm-btn"
                    target="_blank"
                    style="font-weight: 800 !important;"
                    @click.native="modals.newRole = true"
                    >
                    <b-icon icon="plus-circle-fill" class="button-icon" ></b-icon>
                    {{ $t("DashBoardStaff.ButtonRole") }}
                    </n-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                class="dashboard-table-role"
                ref="staffTable"
                id="staffTable"
                :fields="headersRoles" 
                :items="roles"
                :current-page="currentPage"
                :per-page="perPage"
                show-empty
                responsive="sm"> 
                <template #empty="">
                    {{$t('DashBoardStaff.EmptyRole')}}
                </template>
                <template #cell(createdAt)="data">
                  {{ data.item.createdAt | formatDate}}
                </template>
                <template #cell(updatedAt)="data">
                  {{ data.item.updatedAt | formatDate}}
                </template>
                <template #cell(ecosystem_language_module)="data">
                  {{ data.item.ecosystem_language_module | formatDataRole}}
                </template>
                <template #cell(layer_module)="data">
                  {{ data.item.layer_module | formatDataRole}}
                </template>
                <template #cell(message_module)="data">
                  {{ data.item.message_module | formatDataRole}}
                </template>
                <template #cell(organization_module)="data">
                  {{ data.item.organization_module | formatDataRole}}
                </template>
                <template #cell(report_module)="data">
                  {{ data.item.report_module | formatDataRole}}
                </template>
                <template #cell(blog_module)="data">
                  {{ data.item.blog_module | formatDataRole}}
                </template>
                <template #cell(budget_module)="data">
                  {{ data.item.budget_module | formatDataRole}}
                </template>
                <template #cell(settings_module)="data">
                  {{ data.item.settings_module | formatDataRole}}
                </template>
                <template #cell(options)="data">
                    <b-button class="action-table pen" v-b-tooltip.hover.viewport :title="$t('DashBoardStaff.Tooltips.Modify') " @click="openEditRoleModal(data.item)">
                      <b-icon icon="pen"></b-icon>
                    </b-button>
                    <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('DashBoardStaff.Tooltips.Delete')" @click="openDeleteRoleModal(data.item)">
                      <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
                <template #row-details="row">
                    <b-card class="row-details-card">
                        <b-row>
                            <b-col class="row-details-col">
                                {{ row.item }}
                            </b-col>
                        </b-row>
                    </b-card>
                </template>
              </b-table>
              <div class="pagination-container">
                  <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                  </b-pagination>
                  <b-form-select v-model="perPage" class="pagination-select">
                      <b-form-select-option :value="3">3</b-form-select-option>
                      <b-form-select-option :value="6">6</b-form-select-option>
                      <b-form-select-option :value="12">12</b-form-select-option>
                      <b-form-select-option
                          :value="roles.length">{{ $t('DashBoardStaff.Options') }}</b-form-select-option>
                  </b-form-select>
              </div>
              </b-col>  
            </b-row>
            
          </card>
        </b-col>
      </b-row>
        <!-- MODAL MODIFY STAFF -->
      <modal :show.sync="modals.modifyStaff" header-classes="justify-content-center">
          <h4 slot="header" class="title title-up">
            {{ $t("DashBoardStaff.Modals.Modify.Title") }}
          </h4>
          <b-form-group :label=" $t('DashBoardStaff.Modals.Modify.Name')" label-for="input-dataFormModifyStaff-name">
            <b-form-input
              v-model="dataModifyStaff.name"
              id="input-dataFormModifyStaff-name"
              name="input-dataFormModifyStaff-name"
              v-validate="{ required: true, alpha_spaces: true}"
              :state="validateState('input-dataFormModifyStaff-name')"
              aria-describedby="input-dataFormModifyStaff-name-live-feedback"
              size="sm"
              :placeholder=" $t('DashBoardStaff.Modals.Modify.Name')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataFormModifyStaff-name-live-feedback">{{ veeErrors.first('input-dataFormModifyStaff-name') }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label=" $t('DashBoardStaff.Modals.Modify.Surname')" label-for="input-dataFormModifyStaff-surname">
            <b-form-input
              v-model="dataModifyStaff.surname"
              id="input-dataFormModifyStaff-surname"
              name="input-dataFormModifyStaff-surname"
              v-validate="{ required: true, alpha_spaces: true}"
              :state="validateState('input-dataFormModifyStaff-surname')"
              aria-describedby="input-dataFormModifyStaff-surname-live-feedback"
              size="sm"
              :placeholder=" $t('DashBoardStaff.Modals.Modify.Surname')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataFormModifyStaff-surname-live-feedback">{{ veeErrors.first('input-dataFormModifyStaff-surname') }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label=" $t('DashBoardStaff.Modals.Modify.Email')" label-for="input-dataFormModifyStaff-email">
              <b-form-input
              v-model="dataModifyStaff.email"
              id="input-dataFormModifyStaff-email"
              name="input-dataFormModifyStaff-email"
              v-validate="{ email: true }"
              :state="validateState('input-dataFormModifyStaff-email')"
              aria-describedby="input-dataFormModifyStaff-email-live-feedback"
              size="sm"
              :placeholder=" $t('DashBoardStaff.Modals.Modify.Email')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataFormModifyStaff-email-live-feedback">{{ veeErrors.first('input-dataFormModifyStaff-email') }}</b-form-invalid-feedback>
          </b-form-group>
          
          <template slot="footer">
            <n-button class="btn-simple ncm-btn" @click.native="editDataStaff()">
              {{ $t("DashBoardStaff.Modals.Modify.Footer.Save") }} 
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click.native="modals.modifyStaff = false">
                {{ $t("DashBoardStaff.Modals.Modify.Footer.Close") }}  
            </n-button>
          </template>
      </modal>
      <!-- MODAL CREATE STAFF -->
      <modal :show.sync="modals.newStaff" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
            {{ $t("DashBoardStaff.Modals.NewStaff.Title") }}
        </h4>
        <b-form-group :label="$t('DashBoardStaff.Modals.NewStaff.Name')" label-for="input-dataFormNewStaff-name">
          <b-form-input
            v-model="dataFormNewStaff.name"
            id="input-dataFormNewStaff-name"
            name="input-dataFormNewStaff-name"
            v-validate="{ required: true, alpha_spaces: true}"
            :state="validateState('input-dataFormNewStaff-name')"
            aria-describedby="input-dataFormNewStaff-name-live-feedback"
            size="sm"
            :placeholder=" $t('DashBoardStaff.Modals.NewStaff.Name')"
          ></b-form-input>
          <b-form-invalid-feedback id="input-dataFormNewStaff-name-live-feedback">{{ veeErrors.first('input-dataFormNewStaff-name') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label=" $t('DashBoardStaff.Modals.NewStaff.Surname')" label-for="input-dataFormNewStaff-name">
          <b-form-input
            v-model="dataFormNewStaff.surname"
            id="input-dataFormNewStaff-surname"
            name="input-dataFormNewStaff-surname"
            v-validate="{ required: true, alpha_spaces: true}"
            :state="validateState('input-dataFormNewStaff-surname')"
            aria-describedby="input-dataFormNewStaff-surname-live-feedback"
            size="sm"
            :placeholder=" $t('DashBoardStaff.Modals.NewStaff.Surname')"
          ></b-form-input>
          <b-form-invalid-feedback id="input-dataFormNewStaff-surname-live-feedback">{{ veeErrors.first('input-dataFormNewStaff-surname') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label=" $t('DashBoardStaff.Modals.NewStaff.Email')" label-for="input-dataFormNewStaff-email">
          <b-form-input
            v-model="dataFormNewStaff.email"
            id="input-dataFormNewStaff-email"
            name="input-dataFormNewStaff-email"
            v-validate="{ required: true, email: true}"
            :state="validateState('input-dataFormNewStaff-email')"
            aria-describedby="input-dataFormNewStaff-email-live-feedback"
            size="sm"
            :placeholder=" $t('DashBoardStaff.Modals.NewStaff.Email')"
          ></b-form-input>
          <b-form-invalid-feedback id="input-dataFormNewStaff-email-live-feedback">{{ veeErrors.first('input-dataFormNewStaff-email') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('DashBoardStaff.Modals.AssignRole.Label')">
        <b-form-select v-model="selected" :options="options"></b-form-select>
        </b-form-group>
        <div class="row">
          <div class="col">
          <b-form-group :label=" $t('DashBoardStaff.Modals.NewStaff.Password')" label-for="input-dataFormNewStaff-password">
            <div class="d-flex">
                <b-form-input
                v-model="dataFormNewStaff.password"
                id="input-dataFormNewStaff-password"
                name="input-dataFormNewStaff-password"
                v-validate="{ required: true}"
                type="password"
                aria-describedby="input-dataFormNewStaff-password-live-feedback"
                :placeholder="$t('DashBoardStaff.Modals.NewStaff.Password')"
                class="no-border input-lg"
                :state="validateState('input-dataFormNewStaff-password')"
              >
                </b-form-input>
            </div>
            <b-form-invalid-feedback id="input-dataFormNewStaff-password-live-feedback">{{ veeErrors.first('input-dataFormNewStaff-password') }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-2 d-flex show-pass-col">
          <span v-if="!variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
          <span v-if="variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye-slash" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
        </div>
        </div>
        
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" @click="newStaff()">
                {{ $t("DashBoardStaff.Modals.NewStaff.Footer.Save") }}
            </n-button>
            
            <n-button type="danger" class="btn-simple ncm-btn-danger" @click="closeNewStaff()">
                {{ $t("DashBoardStaff.Modals.NewStaff.Footer.Close") }} 
            </n-button>
        </template>
      </modal>
      <!-- MODAL DELETE STAFF -->
      <modal
          :show.sync="modals.deleteStaff"
          class="modal-primary"
          :show-close="false"
          header-classes="justify-content-center"
          type="mini"
        >
        <p class="p-dashboard">{{ $t("DashBoardStaff.Modals.Delete.Title") }}</p>
        <template slot="footer">
          <n-button
            type="neutral"
            link
            @click="deleteUserStaff()"
          >
            {{ $t("Ecosystems.Modals.Delete.Footer.Save") }} 
          </n-button>
          <n-button
            type="neutral"
            link
            @click.native="modals.deleteStaff = false"
          >
            {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
          </n-button>
        </template>
      </modal>
      <!-- MODAL DELETE ROLE -->
      <modal
          :show.sync="modals.deleteRole"
          class="modal-primary"
          :show-close="false"
          header-classes="justify-content-center"
          type="mini"
        >
        <p class="p-dashboard">{{ $t("DashBoardStaff.Modals.DeleteRole.Title") }}</p>
        <template slot="footer">
          <n-button
            type="neutral"
            link
            @click.native="deleteRole()"
          >
            {{ $t("DashBoardStaff.Modals.DeleteRole.Footer.Save") }} 
          </n-button>
          <n-button
            type="neutral"
            link
            @click.native="modals.deleteRole = false"
          >
            {{ $t("DashBoardStaff.Modals.DeleteRole.Footer.Close") }}
          </n-button>
        </template>
      </modal>
      <!-- MODAL CREATE ROLE -->
      <modal
        :show.sync="modals.newRole"
        header-classes="justify-content-center"
      >
        <h4
          slot="header"
          class="title title-up"
        >
        {{ $t("DashBoardStaff.Modals.NewRole.Title") }}
        </h4>
        <b-form-group :label=" $t('DashBoardStaff.Modals.NewRole.Name')" label-for="input-dataFormNewRole-name">
          <b-form-input
            v-model="dataFormNewRole.name"
            id="input-dataFormNewRole-name"
            name="input-dataFormNewRole-name"
            v-validate="{ required: true, alpha_spaces: true}"
            :state="validateState('input-dataFormNewRole-name')"
            aria-describedby="input-dataFormNewRole-name-live-feedback"
            size="sm"
            :placeholder=" $t('DashBoardStaff.Modals.NewRole.Name')"
          ></b-form-input>
          <b-form-invalid-feedback id="input-dataFormNewRole-name-live-feedback">{{ veeErrors.first('input-dataFormNewRole-name') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label=" $t('DashBoardStaff.Modals.NewRole.Permissions')">
        <div class="row">
          <div class="col">
            <n-checkbox type="checkbox" name="chk" v-model="dataFormNewRole.ecosystem_language_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.ecosystem_language_module') }}
            </n-checkbox>
            <n-checkbox type="checkbox" name="chk" v-model="dataFormNewRole.layer_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.layer_module') }}
            </n-checkbox>
            <n-checkbox type="checkbox" name="chk"  v-model="dataFormNewRole.message_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.message_module') }}
            </n-checkbox>
            <n-checkbox type="checkbox" name="chk" v-model="dataFormNewRole.organization_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.organization_module') }}
            </n-checkbox>           
          </div>
          <div class="col">
            <n-checkbox type="checkbox" name="chk" v-model="dataFormNewRole.report_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.report_module') }}
            </n-checkbox>  
            <n-checkbox  type="checkbox"  name="chk" v-model="dataFormNewRole.blog_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.blog_module') }}
            </n-checkbox>      
            <n-checkbox type="checkbox" name="chk" v-model="dataFormNewRole.budget_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.budget_module') }}
            </n-checkbox>
            <n-checkbox type="checkbox" name="chk" v-model="dataFormNewRole.settings_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.settings_module') }}
            </n-checkbox>
          </div>
          
        </div>
        <div class="row">
          <div class="col d-flex mt-3 ">
            <b-button class="btn btn-primary btn-round m-auto " v-if="!isCheckAll" @click="selects()" >
            {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.selectAll') }}
          </b-button>
          <b-button class="btn btn-primary btn-round m-auto" v-if="isCheckAll" @click="deselects()" >
            {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.deselectAll') }}
          </b-button>
          </div>
        </div>
      </b-form-group>
      <template slot="footer">
          <n-button class="btn-simple ncm-btn" @click.native="newRole()">
            {{ $t("DashBoardStaff.Modals.NewRole.Footer.Save") }}
          </n-button>
          
          <n-button class="btn-simple ncm-btn-danger" @click.native="closeNewRole()">
            {{ $t("DashBoardStaff.Modals.NewRole.Footer.Close") }}
          </n-button>
      </template>
      </modal>
      <!-- MODAL EDIT ROLE USER-->
      <modal :show.sync="modals.assignRole" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("DashBoardStaff.Modals.AssignRole.Label") }}
        </h4>
        <b-form-group>
          <b-form-select v-model="selected" :options="options"></b-form-select>
        </b-form-group>
          <template slot="footer">
          <n-button class="btn-simple ncm-btn" @click.native="assignRole()">
            {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Save") }} 
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="modals.assignRole = false">
            {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Close") }} 
          </n-button>
        </template>
      </modal>
      <!-- MODAL MODIFY ROLE -->
      <modal :show.sync="modals.editRole" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("DashBoardStaff.Modals.AssignRole.Label") }}
        </h4>
        <b-form-group :label=" $t('DashBoardStaff.Modals.NewRole.Name')" label-for="input-dataFormNewRole-name">
          <b-form-input
            v-model="roleEdit.name"
            id="input-roleEdit-name"
            name="input-roleEdit-name"
            v-validate="{ required: true, alpha_spaces: true}"
            :state="validateState('input-roleEdit-name')"
            aria-describedby="input-roleEdit-name-live-feedback"
            size="sm"
            :placeholder=" $t('DashBoardStaff.Modals.NewRole.Name')"
          ></b-form-input>
          <b-form-invalid-feedback id="input-roleEdit-name-live-feedback">{{ veeErrors.first('input-roleEdit-name') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label=" $t('DashBoardStaff.Modals.NewRole.Permissions')">
        <div class="row justify-content-center"> 
          <div class="col-sm-6 justify-content-center">
            <n-checkbox type="checkbox" name="chk" v-model="roleEdit.ecosystem_language_module">
            {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.ecosystem_language_module') }}
            </n-checkbox>
            <n-checkbox type="checkbox" name="chk" v-model="roleEdit.layer_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.layer_module') }}
            </n-checkbox>
            <n-checkbox type="checkbox" name="chk"  v-model="roleEdit.message_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.message_module') }}
            </n-checkbox>
            <n-checkbox type="checkbox" name="chk" v-model="roleEdit.organization_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.organization_module') }}
            </n-checkbox>
          </div>
          <div class="col-sm-6 justify-content-center">
            <n-checkbox type="checkbox" name="chk" v-model="roleEdit.report_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.report_module') }}
            </n-checkbox>
            <n-checkbox  type="checkbox"  name="chk" v-model="roleEdit.blog_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.blog_module') }}
            </n-checkbox>
            <n-checkbox type="checkbox" name="chk" v-model="roleEdit.budget_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.budget_module') }}
            </n-checkbox>
            <n-checkbox type="checkbox" name="chk" v-model="roleEdit.settings_module">
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.settings_module') }}
            </n-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col mt-3 d-flex">
              <b-button class="btn btn-round btn-primary m-auto" v-if="!isCheckAll" @click="selects()" >
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.selectAll') }}
            </b-button>
            <b-button class="btn btn-round btn-primary m-auto" v-if="isCheckAll" @click="deselects()" >
              {{ $t('DashBoardStaff.Modals.NewRole.CheckBoxes.deselectAll') }}
            </b-button>
          </div>
        </div>
      </b-form-group>
          <template slot="footer">
          <n-button class="btn-simple ncm-btn" @click.native="editRole()">
            {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Save") }} 
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="modals.editRole = false">
            {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Close") }} 
          </n-button>
        </template>
      </modal>

  </b-container>
  </div> 
</template>

<script>
  const $ = require('jquery')
  import StaffServices from '../../services/StaffServices';
  import RoleServices from '../../services/RoleServices';
  import { Badge, Button, InfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
  import { API_KEY } from '@/constants'
  import {Table, TableColumn, Tooltip} from 'element-ui';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  import { CONNECTING } from 'ws';
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });
export default {
name: 'ProfilePage',
bodyClass: 'profile-page',
components: {
    Modal,
    Card,
    [Checkbox.name]: Checkbox,
    [DropDown.name]: DropDown,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [FormGroupInput.name]: FormGroupInput,
}, 
data() {
    return {
        variablePass: false,
        editing: false,
        selected: null,
        loading: false,
        // Modals and data modals
        modals: {
          newStaff: false,
          modifyStaff: false,
          deleteStaff: false,
          newRole: false,
          deleteRole: false,
          editRole: false,
          assignRole: false
        },
        roleEdit: {},
        idDelete: null,
        dataModifyStaff: {},
        dataFormNewStaff: {},
        staffs: [],
        headers: [
          { key: 'name', sortable: true, label: this.$t('DashBoardStaff.Table.Name'), tdClass: 'table-title', thClass: 'table-title' }, 
          { key: 'surname', sortable: true, label: this.$t('DashBoardStaff.Table.Surname') },
          { key: 'phone', label: this.$t('DashBoardStaff.Table.Phone') },
          { key: 'email', label: this.$t('DashBoardStaff.Table.Email') },
          { key: 'role', label: this.$t('DashBoardStaff.Table.Role') },
          { key: 'is_active', label: this.$t('DashBoardStaff.Table.Active') },
          { key: 'options', label: this.$t('DashBoardStaff.Table.Actions')  }
        ],
        options : [{ value: null, text: ' Whitout role' }],
        roles: [],
        headersRoles: [
          { key: 'name', sortable: true, label: this.$t('DashBoardStaff.Table.NameR'), tdClass: 'table-title', thClass: 'table-title' }, 
          { key: 'ecosystem_language_module', label: this.$t('DashBoardStaff.Table.EcosystemLanguage') },
          { key: 'layer_module', label: this.$t('DashBoardStaff.Table.LayerModule') },
          { key: 'message_module', label: this.$t('DashBoardStaff.Table.MessageModule') },
          { key: 'organization_module', label: this.$t('DashBoardStaff.Table.OrganizationModule') },
          { key: 'report_module', label: this.$t('DashBoardStaff.Table.ReportModule') },
          { key: 'blog_module', label: this.$t('DashBoardStaff.Table.BlogModule') },
          { key: 'budget_module', label: this.$t('DashBoardStaff.Table.BudgetModule') },
          { key: 'settings_module', label: this.$t('DashBoardStaff.Table.SettingsModule') },
          { key: 'createdAt',sortable: true, label: this.$t('DashBoardStaff.Table.CreatedAt')},
          { key: 'updatedAt',sortable: true, label: this.$t('DashBoardStaff.Table.UpdatedAt') },
          { key: 'options', label: this.$t('DashBoardStaff.Table.Actions') }
        ],
        allOpenRows: [],
        dataAssignRole : {},
        idDeleteRole: null,
        isCheckAll: false,
        dataFormNewRole: {
          ecosystem_language_module : false,
          layer_module: false,
          blog_module: false,
          report_module: false,
          message_module: false,
          organization_module: false,
          budget_module: false,
          settings_module: false
        },
        currentPage: 1,
        perPage: 6
    }
  },
  
  created () {
    this.loading = true
    this.getData()
  },
  computed: {
    totalPages(){
      return this.roles.length
    }
  },
  methods : {
    copyToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$swal.fire({
          icon: 'success',
          text: this.$t('Ecosystems.Copied')
      }) 
    },
    changeStatus(staff, active) {
      staff.is_active = active
      StaffServices.modifyStaff(staff).then(
        Response => {
          if (Response.status === 200) {
            this.$swal.fire({
              icon: 'success',
              title: this.$t('DashBoardStaff.Alerts.EditStaff.OK')
            })
          }
        },
        Error => {
          if (Error.response.status === 404) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.ChangeStatus.NotFound')
            })
          }
          if (Error.response.status === 400) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.ChangeStatus.BadRequest')
            })
          }
          if (Error.response.status === 500) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('ErrorHTTPMessages.InternalServerError')
            })
          }
          if (Error.response.status === 401) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('Errors.Forbidden')
            })
          }
        }
      )
    },
    getData(){
      StaffServices.findAll().then(
        Response => {
          if (Response.status === 200) {
            this.staffs = Response.data.data
            this.loading = false
          }
        },
        Error => {
          if (Error.response.status === 404) {
              console.log('Error 404 al obtener los datos de los staffs')
            }
            if (Error.response.status === 400) {
              console.log('Error 400 al obtener los datos de los staffs')
            }
            if (Error.response.status === 500) {
              console.log('Internal server error al obtener datos de los staffs')
            }
            if (Error.response.status === 401) {
              this.$router.push('dashboard-staff')
            }
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: Error.response.data.message
          })
          }
      )
      RoleServices.findAll().then(
        Response => {
          if (Response.status === 200) {
            this.roles = Response.data.data
            this.options =  [{ value: null, text: 'Whitout role' }]
            for (let roleOption of this.roles)
            this.options.push({value: roleOption.id, text: roleOption.name})
          }
        },
        Error => {
            if (Error.response.status === 404) {
              console.log('Error 404 al obtener los datos de los staffs')
            }
            if (Error.response.status === 400) {
              console.log('Error 400 al obtener los datos de los staffs')
            }
            if (Error.response.status === 500) {
              console.log('Internal server error al obtener datos de los staffs')
            }
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: Error.response.data.message
            })
          }
      )
    },
    // CREATE STAFF
    newStaff () {
      this.$validator.validateAll(['input-dataFormNewStaff-name','input-dataFormNewStaff-surname','input-dataFormNewStaff-email','input-dataFormNewStaff-password']).then(result => {
        if (!result) {
          return
        }
        //this.loading = true;
        this.dataFormNewStaff.role_id = this.selected
        StaffServices.createStaff(this.dataFormNewStaff).then(
        Response => {
          if (Response.status === 200) {
            this.$swal.fire({
                icon: 'success',
                title: this.$t('DashBoardStaff.Alerts.CreateStaff.OK')
              }).then(() => {
                this.modals.newStaff = false
                this.getData()
                this.dataFormNewStaff = {}
                this.$validator.reset()
              });
          }
          //this.loading = false;
        },
        Error => {
          this.loading = false;
          if (Error.response.status === 404) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.CreateStaff.NotFound')
            })
          }
          if (Error.response.status === 400) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.CreateStaff.BadRequest')
            })
          }
          if (Error.response.status === 500) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('Errors.InternalServerError')
            })
          }
          if (Error.response.status === 401) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('Errors.Forbidden')
            })
          }
        }
        )
      }) 
    },
    closeNewStaff () {
      this.modals.newStaff = false
      this.$validator.reset()
      this.dataFormNewStaff = {}
    },
    openEditRoleModal (row) {
      this.roleEdit = {... row}
      this.modals.editRole = true
    },
    openEditStaffModal(row) {
      this.dataModifyStaff = {... row}
      this.modals.modifyStaff = true
      this.$validator.reset();
    },
    editRole () {
      this.$validator.validateAll(['input-roleEdit-name']).then(result => {
        if (!result) {
          return
        }
        else 
        {
          if (this.checkRole2()) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.AssignRole.Error')
            })
            return
          }
          RoleServices.modify(this.roleEdit).then(
            Response => {
              if (Response.status === 200) {
                this.$swal.fire({
                    icon: 'success',
                    title: this.$t('DashBoardStaff.Alerts.EditStaff.OK')
                  }).then(() => {
                    this.getData()
                    this.modals.editRole = false
                  });
              }
            },
            Error => {
              if (Error.response.status === 404) {
                this.$swal.fire({
                  icon: 'error',
                  title: this.$t('DashBoardStaff.Alerts.EditStaff.NotFound')
                })
              }
              if (Error.response.status === 400) {
                this.$swal.fire({
                  icon: 'error',
                  title: this.$t('DashBoardStaff.Alerts.EditStaff.BadRequest')
                })
              }
              if (Error.response.status === 500) {
                this.$swal.fire({
                  icon: 'error',
                  title: this.$t('ErrorHTTPMessages.InternalServerError')
                })
              }
              if (Error.response.status === 401) {
                this.$swal.fire({
                  icon: 'error',
                  title: this.$t('ErrorHTTPMessages.Forbidden')
                })
              }
            }
          )
        }
      })
    },
    editDataStaff () {
      this.$validator.validateAll(['input-dataFormModifyStaff-name','input-dataFormModifyStaff-surname','input-dataFormModifyStaff-mail']).then(result => {
        if (!result) {
          return
        }
        StaffServices.modifyStaff(this.dataModifyStaff).then(
        Response => {
          if (Response.status === 200) {
            this.$swal.fire({
                icon: 'success',
                title: this.$t('DashBoardStaff.Alerts.EditStaff.OK')
              }).then(() => {
                this.getData()
                this.modals.modifyStaff = false
              });
          }
        },
        Error => {
          if (Error.response.status === 404) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.EditStaff.NotFound')
            })
          }
          if (Error.response.status === 400) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.EditStaff.BadRequest')
            })
          }
          if (Error.response.status === 500) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('Errors.InternalServerError')
            })
          }
        })
      })
    },
    // DELETE STAFF
    openDeleteStaffModal (row) {
      this.modals.deleteStaff = true
      this.idDelete = row.id
    },
    deleteUserStaff() {
      StaffServices.deleteStaff(this.idDelete).then(
        Response => {
          if (Response.status === 200) {
            this.$swal.fire({
                icon: 'success',
                title: this.$t('DashBoardStaff.Alerts.DeleteStaff.OK')
              }).then(() => {
                this.getData()
                this.modals.deleteStaff = false
              });
          }
        },
        Error => {
          if (Error.response.status === 404) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.DeleteStaff.NotFound')
            })
          }
          if (Error.response.status === 400) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.DeleteStaff.BadRequest')
            })
          }
          if (Error.response.status === 500) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('Errors.InternalServerError')
            })
          }
        }
      )
    },
    // ASSIGN ROLE
    openAssignStaffRole (row){
      this.selected = row.role_id
      this.modals.assignRole = true
      this.dataAssignRole.userId = row.id
      this.dataAssignRole.roleId = row.role_id
      this.dataAssignRole.roleName = row.role_name
    },
    assignRole () {
      StaffServices.setRole(this.dataAssignRole.userId, this.selected).then(
        Response => {
          if (Response.status === 200) {
            this.$swal.fire({
                icon: 'success',
                title: this.$t('DashBoardStaff.Alerts.AssignRole.OK')
              }).then(() => {
                this.getData()
                this.modals.assignRole = false
              });
          }
        },
        Error => {
          if (Error.response.status === 404) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.AssignRole.NotFound')
            })
          }
          if (Error.response.status === 400) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.AssignRole.Error')
            })
          }
          if (Error.response.status === 500) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('Errors.InternalServerError')
            })
          }
        }
      )
    },
    // DELETE ROLE
    openDeleteRoleModal (row) {
      this.modals.deleteRole = true
      this.idDeleteRole = row.id
    },
    deleteRole(force) {
      
      RoleServices.delete(this.idDeleteRole, force).then(
        Response => {
          if (Response.status === 200) {
            this.$swal.fire({
                icon: 'success',
                title: this.$t('DashBoardStaff.Alerts.DeleteRole.OK')
              }).then(() => {
                this.getData()
                this.modals.deleteRole = false
              });
          }
        },
        Error => {
          if (Error.response.status === 404) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.DeleteRole.NotFound')
            })
          }
          if (Error.response.status === 400) {
            this.$swal.fire({
              icon: 'error',
              title: this.$t('DashBoardStaff.Alerts.DeleteRole.BadRequest')
            })
          }
          if (Error.response.status === 500) {
            console.log(Error.response)
            this.$swal.fire({
              icon: 'error',
              title: this.$t('Errors.InternalServerError')
            })
          }
          if (Error.response.status === 409) {
            console.log(Error.response)
            this.$swal.fire({
              icon: 'error',
              showDenyButton: true,
              confirmButtonText: 'Yes',
              title: this.$t('DashBoardStaff.Conflict'),
              text: this.$t('DashBoardStaff.DeleteRole')
            }).then((result) => {
                if (result.isConfirmed) {
                  this.deleteRole(true)
                  this.modals.deleteRole = false 
                } else if (result.isDenied) {
                  this.modals.deleteRole = false
                }
            })
          }
        }
      )
    },

    // CREATE ROLE
    newRole () {
      this.$validator.validateAll(['input-dataFormNewRole-name']).then(result => {
        if (!result) {
          return
        }
        else 
        {
          if (this.checkRole()) {
            this.$swal.fire({
              icon: 'warning',
              title: this.$t('DashBoardStaff.Alerts.AssignRole.Error')
            })
            return
          }
          else {
            RoleServices.create(this.dataFormNewRole).then(
              Response => {
                if (Response.status === 200) {
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('DashBoardStaff.Alerts.CreateRole.OK')
                      }).then(() => {
                        this.getData()
                        this.isCheckAll = false
                        this.modals.newRole = false
                        this.dataFormNewRole = {}
                        this.$validator.reset()
                      });
                  }
              },
              Error => {
                if (Error.response.status === 404) {
                    this.$swal.fire({
                      icon: 'error',
                      title: this.$t('DashBoardStaff.Alerts.CreateRole.NotFound')
                    })
                  }
                  if (Error.response.status === 400) {
                    this.$swal.fire({
                      icon: 'error',
                      title: this.$t('DashBoardStaff.Alerts.CreateRole.BadRequest')
                    })
                  }
                  if (Error.response.status === 500) {
                    this.$swal.fire({
                      icon: 'error',
                      title: this.$t('Errors.InternalServerError')
                    })
                  }
              }
            )
          }
        }
      })
      
    },
    closeNewRole () {
      this.modals.newRole = false
      this.isCheckAll = false
      this.$validator.reset()
      this.dataFormNewRole = {}
    },
    // AUXILIAR FUNCTIONS
    checkRole () {
      if (this.dataFormNewRole.ecosystem_language_module === false &&
      this.dataFormNewRole.blog_module === false  &&
      this.dataFormNewRole.report_module === false  &&
      this.dataFormNewRole.message_module === false  &&
      this.dataFormNewRole.organization_module === false &&
      this.dataFormNewRole.budget_module === false &&
      this.dataFormNewRole.settings_module === false) { 
        return true 
      } else return false
    },
    checkRole2 () {
      if (this.roleEdit.ecosystem_language_module === false &&
          this.roleEdit.blog_module === false  &&
          this.roleEdit.report_module === false  &&
          this.roleEdit.message_module === false  &&
          this.roleEdit.organization_module === false &&
          this.roleEdit.budget_module === false &&
          this.roleEdit.settings_module === false) { 
        return true 
      } else return false
    },
    selects() {  
        this.dataFormNewRole.ecosystem_language_module = true,
        this.dataFormNewRole.layer_module = true,
        this.dataFormNewRole.blog_module = true,
        this.dataFormNewRole.report_module = true,
        this.dataFormNewRole.message_module = true,
        this.dataFormNewRole.organization_module = true,
        this.dataFormNewRole.budget_module = true,
        this.dataFormNewRole.settings_module = true
        this.isCheckAll = true
        this.roleEdit.ecosystem_language_module = true,
        this.roleEdit.layer_module = true,
        this.roleEdit.blog_module = true,
        this.roleEdit.report_module = true,
        this.roleEdit.message_module = true,
        this.roleEdit.organization_module = true,
        this.roleEdit.budget_module = true,
        this.roleEdit.settings_module = true
    },  
    deselects() {  
        this.dataFormNewRole.ecosystem_language_module = false,
        this.dataFormNewRole.layer_module = false,
        this.dataFormNewRole.blog_module = false,
        this.dataFormNewRole.report_module = false,
        this.dataFormNewRole.message_module = false,
        this.dataFormNewRole.organization_module = false,
        this.dataFormNewRole.budget_module = false,
        this.dataFormNewRole.settings_module = false
        this.isCheckAll = false
        this.roleEdit.ecosystem_language_module = false,
        this.roleEdit.layer_module = false,
        this.roleEdit.blog_module = false,
        this.roleEdit.report_module = false,
        this.roleEdit.message_module = false,
        this.roleEdit.organization_module = false,
        this.roleEdit.budget_module = false,
        this.roleEdit.settings_module = false
    },  
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    showPass () {
      if (!this.variablePass) this.variablePass = true
      else this.variablePass = false
      var tipo = document.getElementById("input-dataFormNewStaff-password");
      if (tipo.type == "password"){
        tipo.type = "text";
      } else {
        tipo.type = "password";
      }
    }
  }
}
</script>
<style>
  .pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
</style>